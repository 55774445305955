<template>
  <div class="box">
    <card :title="title">
      <div class="centext">
        <div class="ql-editor" v-html="htmltext"></div>
     
      </div>
    </card>
  </div>
</template>

<script>
import card from "../../all/card"; //封装的卡片样式
export default {
  name: "member", //首页新闻展示
  props: {
    // 卡片标题
    title: {
      typeof: [String],
    },
  },
  data() {
    return {
      htmltext:''
    };
  },
  components: {
    card,
  },
  created() {
    this.getdetai();
  },
  methods: {
    changenar() {
      this.$emit("changenar");
    },
    // 获取当前富文本
    getdetai() {
      var obj = {
        current: 1,
        size: 1,
        code: "CYLB",
      };
      this.$api.getMember(obj).then((res) => {
        this.htmltext = res.data.records[0].content;
      });
    },
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1024px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    /deep/ .box {
      background: #ffffff;
    }
    /deep/ .box:hover {
      box-shadow: none;
    }
    .centext {
      h1 {
        font-size: 1.25rem;
        line-height: 1.8;
        margin: 0.625rem 0;
      }
      h4 {
        font-size: 1.125rem;
        line-height: 1.8;
      }
      .centent {
        font-size: 1rem;
        line-height: 1.8;
      }
      .joinbtn {
        margin: 2.5rem 0px 1.25rem;
        background-color: rgb(51, 142, 196);
        border-radius: 0.225rem;
        color: rgb(255, 255, 255);
        width: 12.5rem;
        height: 3.125rem;
      }
      .joinbtn:hover {
        background-color: rgba(65, 75, 206, 0.933);
      }
      .joinbtn:active {
        opacity: 0.8;
      }
    }
    /deep/ .cardcent {
      min-height: 8rem;
    }
  }
}

@media screen and (max-width: 1023px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    .centext {
      h1 {
        font-size: 1.125rem;
        line-height: 1.8;
        margin: 0.625rem 0;
      }
      h4 {
        font-size: 1rem;
        line-height: 1.8;
      }
      .centent {
        font-size: 0.8rem;
        line-height: 1.8;
      }
      .joinbtn {
        margin: 1.5rem 0px 0.6rem;
        background-color: rgb(51, 142, 196);
        border-radius: 0.225rem;
        color: rgb(255, 255, 255);
        width: 8rem;
        height: 2rem;
      }
      .joinbtn:active {
        opacity: 0.8;
      }
    }
    /deep/ .cardcent {
      min-height: 8rem;
    }
  }
}
</style>

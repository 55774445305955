<template>
  <!-- 工作组内容-->
  <div class="box">
    <div v-if="chinum != joinChinum">
      <div class="bombox">
        <suggest :title="narlist[0].name" />
      </div>
      <div class="bombox">
        <member :title="narlist[1].name" />
      </div>    
      <div class="bombox">
        <info  :title="narlist[3].name" />
      </div>

        <!-- 加人我们的按钮 -->
      <div class="flexcent">
        <!-- <div @click="changenar" class="flexcent joinbtn">加入我们</div> -->
      </div>
    </div>
    <!-- chinum==2时展示加入我们 -->
    <div v-if="chinum == joinChinum" class="bombox">
      <join :title="narlist[2].name" />
    </div>
  </div>
</template>

<script>
import suggest from "./suggest"; // 工作组介绍
import member from "./member"; //成员列表
import join from "./join"; //加入我们
import info from "./info"; //加入我们

export default {
  name: "about", //工作组
  props: {
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
     // 当前的页码(1级菜单)
    narlist: {
      typeof: [Array],
      default: 1,
    },
  },
  components: {
    suggest, //工作组介绍
    member, //成员列表
    join, //加入我们
    info, //联系我们
  },
  data() {
    return {
      secondary: 0, //二级菜单的选中样式
      socllist: [0, 400, 0, 2500],
      // socllist: [0, 400, 2500],

      // 给加入我们预留一个位置
      joinChinum: 2
    };
  },
  // 进入页面同步数据
  created() {
    this.secondary = JSON.stringify(this.chinum); //同步一级菜单
  },
  mounted() {
    if (this.chinum != this.joinChinum) {
      var time = setTimeout(()=>{
        console.log( this.chinum, this.joinChinum)
        console.log(this.socllist[this.chinum / 1]);
        document.documentElement.scrollTop = this.socllist[this.chinum / 1];
        console.log(" document.documentElement.scrollTop ", document.documentElement.scrollTop);
        clearTimeout(time)
      },100)
    }else{
      document.documentElement.scrollTop =0
    }
  },
  methods: {
    // 点击切换菜单样式
    changenar() {
      // 传出当前选中菜单
      this.$emit("changenar", { pagenum: 5, chinum: 2 });
    },
  },
};
</script>

<style scoped lang="less">

 .joinbtn {
        margin: 2.5rem 0px 1.25rem;
        background-color: rgb(51, 142, 196);
        border-radius: 0.225rem;
        color: rgb(255, 255, 255);
        width: 12.5rem;
        height: 3.125rem;
      }
      .joinbtn:hover {
        background-color: rgba(65, 75, 206, 0.933);
      }
      .joinbtn:active {
        opacity: 0.8;
      }

@media screen and (min-width: 1024px) {
  /deep/ .cardcent {
    min-height: 30vh;
  }
  .bombox {
    margin-bottom: 1.25rem;
  }
  /deep/ .cardhead {
    height: 3.75rem !important;
    background-color: #e1e1e6;
    .more {
      display: none;
    }
  }
}

@media screen and (max-width: 1023px) {
  /deep/ .more {
    display: none;
  }
}
</style>

<template>
  <div class="box">
    <card :title="title">
      <div class="centext">

         <div class='info'> 电话：（010）64102856 </div>
         <div class='info'> 传真：（010）64007681 </div>
         <div class='info'> 邮编： 100007 </div>
         <div class='info'> 地址： 北京市东城区安定门东大街1号 </div>
        </div>

    </card>
  </div>
</template>

<script>
import card from "../../all/card"; //封装的卡片样式
export default {
  name: "suggest", //首页新闻展示
  props: {
    // 卡片标题
    title: {
      typeof: [String],
    },
  },
  data() {
    return {
      htmltext: "",
    };
  },
  created() {
    // this.getdetai();
  },
  methods: {
    // 获取当前富文本
    // getdetai() {
    //   var obj = {
    //     current: 1,
    //     size: 1,
    //     code: "GZZJS",
    //   };
    //   this.$api.getMember(obj).then((res) => {
    //     this.htmltext = res.data.records[0].content;
    //   });
    // },
  },
  components: {
    card,
  },
};
</script>


<style scoped lang="less">

@media screen and (min-width: 1024px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    /deep/ .box {
      background: #ffffff;
    }
    /deep/ .box:hover {
      box-shadow: none;
    }
    .centext {
      text-indent: 2em;
      font-size: 1.125rem;
      line-height: 2.5rem;
    }
    /deep/ .cardcent {
      min-height: 8rem;
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 1023px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    .centext {
      text-indent: 2em;
      font-size: 0.9rem;
      line-height: 2rem;
    }
    /deep/ .cardcent {
      min-height: 8rem;
      padding-left: 0;
    }
  }
}
</style>

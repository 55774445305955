<template>
  <!-- 卡片样式 -->
  <div class="box" v-if="narlist.length > 0">
    <div class="title flexcent">
      <span>
        {{ narlist[pagenum].name }}
      </span>      
    </div>
    <div class="tabbox">
      <div v-for="(item, index) in narlist[pagenum].children" :key="index">
        <div
          class="flexcent"
          :class="{ actives: index == secondary }"
          @click="linkpage(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: "leftab", //卡片
  props: {
    // 当前的页码(1级菜单)
    pagenum: {
      typeof: [Number],
      default: 0,
    },
    // 当前的页码(2级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
  },
  // computed: {
  //   d: function() {
  //     console.log('ttt', this.chinum)
  //     return this.chinum
  //   }
  // },
  watch: {
    chinum: {
      handler(val) {
        // console.log('val secondarysecondary', val, oldVal)
        this.secondary = val
      },
      immediate: true
    }
  },
  created() {
    this.secondary = this.chinum;
    this.getlist();
  },
  data() {
    return {
      narlist: [], //当前导航栏数组
      secondary: 0, //二级标题
    };
  },
  methods: {
    // 进入页面加载导航栏数据
    getlist() {
      // 发起请求
      this.$api.getNavigation({}).then((res) => {
        this.narlist = res.data;
      });
    },
    // 点击更多时触发
    linkpage(index) {
      console.log('chagenar', index)
      this.secondary = index;
      this.$emit("changenar", { pagenum: this.pagenum, chinum: index });
    },
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1024px) {
  .box {
    width: 100%;
    height: 100%;
    // 一级标题
    .title {
      height: 3.75rem;
      background-color: #8cb9e6;
      color: #0e4efd;
      font-size: 1.25rem;
      font-weight: 550;
      margin-bottom: 0.625rem;
      background-image: url(../../assets/tabTitle.png);
      align-items:end;
      span {
        padding-bottom: 0.7rem;
        border-bottom: 0.3rem solid #0e4efd;
      }
    }
    // 二级标题
    .tabbox {
      background-color: #ffffff;
      box-shadow: 0 0 0.3125rem #c5c5c5;
      div {
        height: 3.5rem;
        border-bottom: 0.0625rem solid #dadada;
        background-color: #f1f5f9;
      }
      div:hover {
        // background-color: #cddbeb;
        cursor: pointer;
        color: white;
        background-image: linear-gradient(90deg,#1065fb,#1065fb 0,#40b2fb 100%,#40b2fb 0);
      }
      .actives {
        // background-color: #ebf1f8;
        // color: #333333;
        color: white;
        background-image: linear-gradient(90deg,#1065fb,#1065fb 0,#40b2fb 100%,#40b2fb 0);
      }
    }
  }
}

@media screen and (max-width: 1023px) {
}
</style>

<template>
  <div class="box">
    <card :title="title">
      <div class="centent">
        <!-- <tabfrom :list="list" /> -->
        <div 
          class="joinInfo"
        >
          请有意愿加入工作组的单位下载申请表，填写信息并盖单位公章后将纸质版申请表邮寄至工作组秘书处、电子版申请表反馈至工作组秘书处邮箱。
        </div>
        <div
          class="joinInfo"
        >
          工作组秘书处地址：北京市东城区安定门东大街1号，秘书处，（010）64102856
        </div>
        <div
          class="joinInfo"
        >
          工作组秘书处邮箱：cppb_wg@cesi.cn
        </div>
        <div
          class="joinInfo"
        >
          工作组申请表：<a href="/admin/sys-file/cppb/成员单位报名表.doc">下载</a>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import card from "../../all/card"; //封装的卡片样式
// import tabfrom from "./tabfrom"; //封装的卡片样式

export default {
  name: "join", //首页新闻展示
  props: {
    // 卡片标题
    title: {
      typeof: [String],
    },
  },
  components: {
    card,
    // tabfrom,
  },
  data() {
    return {
      list: [
        {
          id: "name",
          title: "单位名称",
          value: "",
          max: 128,
          plalder: "请输入单位名称",
          password: false,
        },
        {
          id: "nature",
          title: "单位性质",
          value: "",
          max: 128,
          plalder: "请输入单位性质",
          password: false,
        },
        {
          id: "postcode",
          title: "邮政编码",
          value: "",
          max: 6,
          plalder: "请输入邮政编码",
          password: false,
        },
        {
          id: "header",
          title: "负责人",
          value: "",
          max: 6,
          plalder: "请输入负责人",
          password: false,
        },
        {
          id: "linkman",
          title: "联系人",
          value: "",
          max: 32,
          plalder: "请输入联系人",
          password: false,
        },
        {
          id: "linktele",
          title: "联系电话",
          value: "",
          max: 11,
          plalder: "请输入联系电话",
          password: false,
        },
        {
          id: "email",
          title: "联系人邮箱",
          value: "",
          max: 32,
          plalder: "请输入联系人邮箱",
          password: false,
        },
        {
          id: "username",
          title: "登录名",
          value: "",
          max: 32,
          plalder: "请输入登录名",
          password: false,
        },
        {
          id: "pwd",
          title: "密码",
          value: "",
          max: 32,
          plalder: "请输入密码",
          password: true,
        },
        {
          id: "pwd2",
          title: "确认密码",
          value: "",
          max: 32,
          plalder: "请再次输入密码",
          password: true,
        },
        {
          id: "groupCode",
          title: "专题组",
          value: "",
          list: [
            {
              id: "",
              name: "组长",
              istrue: false,
            },
            {
              id: "",
              name: "副组长",
              istrue: false,
            },
            {
              id: "",
              name: "秘书长",
              istrue: false,
            },
            {
              id: "",
              name: "秘书处",
              istrue: false,
            },
            {
              id: "",
              name: "标准研制专题组组长",
              istrue: false,
            },
            {
              id: "",
              istrue: false,
              name: "标准研制专题组成员",
            },
            {
              id: "",
              istrue: false,
              name: "工具研制专题组组长",
            },
            {
              id: "",
              istrue: false,
              name: "工具研制专题组成员",
            },
            {
              id: "",
              istrue: false,
              name: "运营专题组组长",
            },
            {
              id: "",
              istrue: false,
              name: "运营专题组成员",
            },
          ],
        },
        {
          id: "address",
          title: "通信地址",
          value: "",
          plalder: "请输入通信地址",
        },
        {
          id: "htmlRef",
          title: "介绍",
          value: "",
        },
      ],
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    getlist() {
      // 获取用户角色数据
      this.$api.getcppbgetRoles().then((res) => {
        for (let a = 0; a < res.data.length; a++) {
          for (let i = 0; i < this.list[10].list.length; i++) {
            if (res.data[a].roleName == this.list[10].list[i].name) {
              this.list[10].list[i].id = res.data[a].roleId;
            }
          }
        }
      });
    },
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1024px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    /deep/ .box{
      background: #ffffff;
    }
    /deep/ .box:hover{
      box-shadow: none;
    }
  }
  .joinInfo {
    margin-left: 2rem;
    font-size: 1.125rem;
    line-height: 2.5rem;
  }
}

@media screen and (max-width: 1023px) {
}
</style>
